<template>
  <div class="meet-make-detail">
    <van-popup
      v-model:show="dataVisible"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        @confirm="confirmDate"
        @cancel="dataVisible = false"
        v-model="currentDate"
        type="date"
        :formatter="formatter"
        title="选择年月日"
      />
    </van-popup>
    <div class="meet-make-detail-view">请选择日期</div>
    <div class="meet-make-detail-date" @click="dataVisible = true">
      <div class="meet-make-detail-date-flex">{{ year }} <span>年</span></div>
      <div class="meet-make-detail-date-flex">{{ month }} <span>月</span></div>
      <div class="meet-make-detail-date-flex">{{ day }} <span>日</span></div>
    </div>
    <div class="meet-make-detail-view">请选择会议室（展开查看更多会议室）</div>
    <div
      class="meet-make-detail-tab"
      :style="{
        overflow: openShow ? '' : 'hidden',
        height: openShow ? '' : '58px',
      }"
    >
    <template v-if="tabList && tabList.length > 0">
      <div
        class="meet-make-detail-tab-item"
        :class="[active === index ? 'active' : '']"
        v-for="(item, index) in tabList"
        :key="index"
        @click="changeTab(index, 0,item.meetingRoomName,$event,item.id)"
        @mouseover="changeMeetStyle($event,index)"
        @touchstart='changeMeetStyle($event,index)'
      >
        {{ item.meetingRoomName }}
      </div>
      <div class="meet-make-detail-tab-item" style="height:0"></div>
    </template>
    <div v-else style="line-height:28px;width:100%;text-align:center">暂无会议室信息</div>
      <p class="meet-make-detail-tab-open" @click="openTab" v-if="tabList.length > 9">
        {{ !openShow ? '展开⬇⬇⬇' : '收起⬆⬆⬆' }}
      </p>
    </div>
    <div class="meet-make-detail-time">
      <div class="meet-make-detail-time-border">
        <!-- :class="[timeActive === index ? 'active' : '']" -->
        <div
          v-for="(item, index) in timeList.slice(8)"
          :key="index"
          :class='{"meet-make-detail-time-border-item":true, blank:item.style===0, halfblank:item.style===1, active:item.style===2, rightblank:item.style===3}'
          @click="changeTab(index, 1,item,$event)"
          @mouseover="changeTabStyle($event,index)"
          @touchstart='changeTabStyle($event,index)'
        >
          {{ item.timeStr }}
        </div>
      </div>
    </div>
    <div class="meet-make-detail-list">
      <template v-if="meetingList.length>0">
      <div
        v-for="(item,index) in meetingList"
        :key="index"
        class="meet-make-detail-list-item"
        @click="$router.push({
          path:'/meet/project-detail',
          query:{
            meetingId:item.id
          }
        })"
      >
        <div class="meet-make-detail-list-item-left">
          <p class="meet-make-detail-list-item-left-name">{{item.meetingName}}的{{item.bookingPersonName}}预约的会议</p>
          <!-- <p class="meet-make-detail-list-item-left-name">{{item.bookingPerson}}预约的{{item.meetingName}}</p> -->
          <p class="meet-make-detail-list-item-left-time">{{item.meetingStartTimeStr+'~'+item.meetingEndTimeStr.slice(item.meetingEndTimeStr.indexOf(' '))}} | {{new Date(item.meetingStartTimeStr).getTime() -  Date.now() > 0?"未开始": Date.now() - new Date(item.meetingEndTimeStr).getTime() > 0?'已结束':"正在进行"}}</p>
        </div>
        <div class="meet-make-detail-list-item-right">
          <!-- --- -->
        </div>
      </div>
      </template>
      <div v-else style="width:100%;text-align:center;margin-top:20px">
        暂无会议
      </div>
    </div>
    <div class="meet-make-detail-add" @click="jumpTo">
      +
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
import { useStore } from '@/store'
import { getMeetingRoomList, getMeetingList } from '@/api/meet'
import { Toast } from 'vant'
var dayjs = require('dayjs')
export default defineComponent({
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const tabList = ref([])
    const meetingList = ref([])
    const currentMeetRoom = ref(null)
    const currentTime = ref(null)
    const openShow = ref(true)
    const active = ref(0)
    const timeActive = ref(0)
    const timeList = ref([])
    const tempTimeList = ref([])
    const dataVisible = ref(false)
    const month = ref(dayjs().month() + 1)
    const day = ref(dayjs().date())
    const year = ref(dayjs().year())
    const changeTab = (index, type, item, e, itemid) => {
      const width = document.querySelector('.meet-make-detail-time-border-item').offsetWidth
      // console.log('width', width)
      if (type === 0) {
        active.value = index
        console.log('item', item, index, itemid)
        store.commit('SET_SELECTMRRTINFO', { name: item, id: index, itemid })
        currentMeetRoom.value = item
        getMeetingListFunc()
      } else {
        console.log('===>', e)
        const startTime = dayjs(dealTimeFormat() + ' ' + item.timeStr.slice(0, item.timeStr.indexOf('~'))).format('HH:mm:ss')
        const endTime = dayjs(dealTimeFormat() + ' ' + item.timeStr.slice(item.timeStr.indexOf('~') + 1)).format('HH:mm:ss')
        if (item.style === undefined || item.style === 0) {
          currentTime.value = dealTimeFormat() + 'T' + item.timeStr
        }
        if (item.style === 1) {
          if (e.offsetX % width <= (width / 2)) {
            Toast('会议室该时间段已有会议请重新选择')
            return false
          }
          if (dayjs(item.scope[1]).format('HH:mm') === '23:30' || dayjs(item.scope[1]).format('HH:mm') === '00:00') {
            Toast('会议室该时间段已有会议请重新选择')
            return false
          }
          currentTime.value = dealTimeFormat() + 'T' + (item.timeStr.slice(0, item.timeStr.indexOf('~')).replace('00', '30')) + ':00' + '~' + endTime
          console.log('asdads', dayjs(item.scope[1]).format('HH:mm'))
        }
        if (item.style === 3) {
          if (e.offsetX % width >= (width / 2)) {
            Toast('会议室该时间段已有会议请重新选择')
            return false
          }
          currentTime.value = dealTimeFormat() + 'T' + startTime + '~' + (item.timeStr.slice(0, item.timeStr.indexOf('~')).replace('00', '30')) + ':00'
        }
        if (item.style === 2) {
          Toast('会议室该时间段已有会议请重新选择')
          return false
        }
        // console.log('===>', currentTime.value)
        timeActive.value = index
        if (currentTime.value.indexOf('24:00') !== -1) {
          currentTime.value = currentTime.value.replace('24:00', '23:30')
          console.log(currentTime.value)
        }
        router.push({
          path: '/meet/make-order',
          query: {
            time: currentTime.value,
            // roomId: currentMeetRoom.value
            roomId: store.state.selectMeetInfo.name || currentMeetRoom.value
          }
        })
      }
    }
    const changeTabStyle = (e, index) => {
      // console.log(e, index)
      timeActive.value = index
    }
    const changeMeetStyle = (e, index) => {
      // console.log(e, index)
      active.value = index
    }
    const formatter = (type, val) => {
      if (type === 'year') {
        return `${val}年`
      }
      if (type === 'month') {
        return `${val}月`
      }
      if (type === 'day') {
        return `${val}日`
      }
      return val
    }
    onBeforeRouteLeave((to, from) => {
      // console.log('======>', to, from)
      store.commit('SET_CURRENTTIME', year.value + '-' + month.value + '-' + day.value)
    })

    onMounted(async () => {
      // 写入时间
      if (store.state.currentTime !== '') {
        year.value = store.state.currentTime.split('-')[0]
        month.value = store.state.currentTime.split('-')[1]
        day.value = store.state.currentTime.split('-')[2]
      }
      // console.log(JSON.stringify(store.state.selectMeetInfo).length)
      // if (JSON.stringify(store.state.selectMeetInfo).length > 31) {
      //   active.value = store.state.selectMeetInfo.id
      // } else {
      //   console.log('modify active')
      //   active.value = 0
      // }
      timeForeach()
      await getMeetingRoomListFunc()
      await getMeetingListFunc()
      // 需要重新加载
      currentTime.value = String(year.value) + '-' + String(month.value >= 10 ? month.value : 0 + String(month.value)) + '-' + String(day.value >= 10 ? day.value : 0 + String(day.value)) + 'T' + '08:00:00~09:00:00'
    })
    const dealTimeFormat = () => {
      return String(year.value) + '-' + String(month.value >= 10 ? month.value : 0 + String(month.value)) + '-' + String(day.value >= 10 ? day.value : 0 + String(day.value))
    }
    // 获取会议室列表
    const getMeetingRoomListFunc = async () => {
      const data = await getMeetingRoomList({ siteId: route.query.id })
      tabList.value = data.data
      // 匹配存储的roomId，如果是不同站点或搜索不到取第一项
      const meetName = data.data.findIndex((el) => {
        return store.state.selectMeetInfo.name === el.meetingRoomName
      })
      if (data.data.length > 0) {
        currentMeetRoom.value = meetName !== -1 ? data.data[meetName].meetingRoomName : data.data[0].meetingRoomName
        active.value = meetName !== -1 ? meetName : 0
      }
      if (meetName > 2) {
        openShow.value = true
      }
    }
    // 获取当前天的当前会议列表
    const getMeetingListFunc = async () => {
      // 处理roomId
      const meetingRoomName = tabList.value.length > 0 ? tabList.value.find(el => {
        return el.meetingRoomName === currentMeetRoom.value
      }).id : ''
      // const openId = store.state.openId || window.localStorage.getItem('openid')
      const data = await getMeetingList({ siteId: store.state.siteId, meetingRoomId: meetingRoomName, meetingDateStr: dealTimeFormat() })
      meetingList.value = data.data.records
      // console.log('temp', tempTimeList.value)
      // console.log(meetingList.value)
      // 处理时间样式
      timeList.value = JSON.parse(JSON.stringify(tempTimeList.value))
      console.log(timeList.value, '----------timeList.value')
      console.log(meetingList.value, '-----------meetingList.value')
      // console.log(timeList.value)
      for (const m in meetingList.value) {
        const start = new Date(meetingList.value[m].meetingStartTimeStr.replace(/-/g, '/')).getTime()
        const end = new Date(meetingList.value[m].meetingEndTimeStr.replace(/-/g, '/')).getTime()
        // console.log(start, end)
        const tempArrStart = []
        const tempArrEnd = []
        for (const n in timeList.value) {
          const temptimeList = dealTimeFormat().replace(/-/g, '/') + ' ' + timeList.value[n].timeStr
          console.log(temptimeList, '------------temptimeList--------')
          timeList.value[n].scope = [new Date(temptimeList.slice(0, temptimeList.indexOf('~'))).getTime(), new Date(temptimeList.slice(0, temptimeList.indexOf(' ')) + ' ' + temptimeList.slice(temptimeList.indexOf('~') + 1)).getTime()]
          // console.log(timeList.value)
          if (timeList.value[n].scope.includes(start) || (start > timeList.value[n].scope[0] && start < timeList.value[n].scope[1])) {
            // console.log('====>', n, start)
            tempArrStart.push(n)
          }
          if (timeList.value[n].scope.includes(end) || (end > timeList.value[n].scope[0] && end < timeList.value[n].scope[1])) {
            // console.log('====>', n, start)
            tempArrEnd.push(n)
          }
        }
        console.log('max', tempArrStart, 'min', tempArrEnd)
        switch (tempArrStart.length) {
          case 0:
            break
          case 1:
            // console.log('开头', Math.max(...tempArrStart))
            console.log('start', String(dayjs(start).format('HH:mm:ss')))
            if (String(dayjs(start).format('HH:mm:ss')) === '08:00:00') {
              timeList.value[Math.max(...tempArrStart)].style = 1
            } else {
              console.log('3333start')
              timeList.value[Math.max(...tempArrStart)].style = 3
            }
            switch (tempArrEnd.length) {
              case 0:
                break
              case 1:
                dealStyle(tempArrStart, tempArrEnd, 1)
                break
              case 2:
                console.log('end2')
                dealStyle(tempArrStart, tempArrEnd, 2)
                break
            }
            break
          case 2:
            { const part = Math.min(...tempArrEnd) - Math.max(...tempArrStart)
              timeList.value[Math.max(...tempArrStart)].style = 1
              switch (tempArrEnd.length) {
                case 0:
                  break
                case 1:
                  // console.log('1', part)
                  dealStyle(tempArrStart, tempArrEnd, 1)
                  break
                case 2:
                  // console.log('2', part)
                  dealStyle(tempArrStart, tempArrEnd, 2)
                  break
              }
            }
            break
        }
      }
    }
    const dealStyle = (start, end, num) => {
      timeList.value[Math.min(...end)].style = num
      const temmdiff = []
      for (let y = Math.max(...start); y < Math.min(...end); y++) {
        temmdiff.push(y)
      }
      // console.log('间隔', temmdiff)
      for (const x in temmdiff) {
        if (timeList.value[temmdiff[x]].style === 3) {
          timeList.value[temmdiff[x]].style = 3
        } else {
          timeList.value[temmdiff[x]].style = 2
        }
      }
      if (temmdiff.length === 0 && start.length === 1) {
        timeList.value[Math.max(...start)].style = 3
      }
    }
    const openTab = () => {
      openShow.value = !openShow.value
    }
    const timeForeach = () => {
      for (var i = 0; i < 24; i++) {
        // for (var j = 0; j <= 24; j++) {
        // if (j - i === 1) {
        timeList.value.push({ timeStr: (i >= 10 ? i : ('0' + i)) + ':00~' + ((i + 1) >= 10 ? (i + 1) : ('0' + (i + 1))) + ':00' })
        // }
        // }
      }
      tempTimeList.value = JSON.parse(JSON.stringify(timeList.value))
    }
    const confirmDate = e => {
      year.value = dayjs(e).year()
      month.value = dayjs(e).month() + 1
      day.value = dayjs(e).date()
      dataVisible.value = false
      // console.log(String(year.value) + '-' + String(month.value > 10 ? month.value : 0 + String(month.value)) + '-' + String(day.value > 10 ? day.value : 0 + String(day.value)))
      getMeetingListFunc()
    }
    const jumpTo = () => {
      router.push({
        path: '/meet/make-order',
        query: {
          time: currentTime.value,
          roomId: store.state.selectMeetInfo.name || currentMeetRoom.value
        }
      })
    }
    return {
      year,
      month,
      day,
      formatter,
      confirmDate,
      currentDate: ref(new Date()),
      dataVisible,
      timeActive,
      timeList,
      openTab,
      openShow,
      active,
      changeTab,
      changeTabStyle,
      changeMeetStyle,
      tabList,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      meetingList,
      jumpTo
    }
  }
})
</script>

<style lang="scss" scoped>
@mixin before-style{
  content: '';
  width: 50%;
  height: 100%;
  display: block;
  position: absolute;
  z-index: -1;
  background: #ba75f5 ;
}
.meet-make-detail-list-item:hover{
  background-color: rgba(156, 156, 156,.3);
  border-radius: 8px;
}
.meet-make-detail-list-item-left-name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.meet-make-detail {
  &-view {
    font-size: 14px;
    color: #999;
    padding: 7px 15px;
    background: #f0f0f0;
  }
  &-date {
    display: flex;
    justify-content: space-between;
    margin: 10px 15px;
    &-flex {
      display: flex;
      border: 1px solid #333;
      display: flex;
      justify-content: space-around;
      border: 1px solid #333;
      width: 30%;
      border-radius: 5px;
      padding: 3px 0;
    }
  }
  &-tab {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    &-item {
      width: 30%;
      text-align: center;
      height: 28px;
      line-height: 28px;
      background: #d1d1d1;
      font-size: 14px;
      color: #666;
      border-radius: 8px;
      margin-bottom: 15px;
    }
    &-item.active {
      background: #3b94fe;
      color: #fff;
    }
    &-open {
      position: absolute;
      bottom: 0px;
      font-size: 12px;
      transform: translateX(-50%);
      left: 50%;
    }
  }
  &-time {
    padding: 15px;
    &-border {
      border-left: 1px solid #666;
      border-bottom: 1px solid #666;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-radius: 8px;
      overflow: hidden;
      &-item {
        flex: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        font-size: 12px;
        border-top: 1px solid #666;
        border-right: 1px solid #666;
        background: white;
        background-color: #fff;
        position: relative;
        z-index: 2;
      }
      &-item.active {
        background: #ba75f5;
      }
      &-item:nth-child(4) {
        border-top-right-radius: 8px;
      }
      &-item:last-child {
        border-bottom-right-radius: 8px;
      }
      &-item:first-child {
        border-top-left-radius: 8px;
      }
    }
  }
  &-time:after {
    content: '';
    width: 20%;
  }
  &-list {
    border-top: 1px solid #ccc;
    margin: 0 15px;
    &-item {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      &-left {
        &-name {
          color: #666;
          font-size: 16px;
        }
        &-time {
          color: #ccc;
          font-size: 12px;
          margin-top: 5px;
        }
      }
      &-right {
      }
    }
  }
  &-add {
    width: 40px;
    height: 40px;
    background: #3b94fe;
    display: flex;
    justify-content: center;
    position: absolute;
    line-height: 40px;
    bottom: 50px;
    right: 20px;
    font-size: 30px;
    color: #fff;
    border-radius: 50%;
    z-index: 99;
  }
}
// .meet-make-detail-date-flex{
//   line-height: 17px;
// }
.meet-make-detail-tab-item{
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
// .blank{
//   background-color: #fff;
//   position: relative;
//   z-index: 2;
// }
.halfblank::before{
  @include before-style;
  left: 0;
}
.rightblank::before{
  @include before-style;
  left: 50%;
}
</style>
